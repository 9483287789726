import mutationTypes from '@/store/mutation-types';
import removeNamespace from '@/helpers/removeNamespace';
import {
    OBJECT_TYPE_DEPLOYED_ADS,
    OBJECT_TYPE_AD,
    OBJECT_TYPE_ADSET,
    OBJECT_TYPE_CAMPAIGN,
    PLATFORM_FACEBOOK,
    PLATFORM_SNAPCHAT,
    PLATFORM_TIKTOK, PLATFORM_PINTEREST,
} from '@/helpers/globals';
import hashCode from '@/helpers/hashCode';
import { getSimpleDate } from '@/helpers/dateUtils';
import {
    getPreviewStructureFromState,
    getPreviewStructure
} from './getPreviewStructure';
import {
    getInitialStructure,
    getStateTypeMap,
    mutationTypeMap,
    sumArrayLengths,
    HIERARCHY,
    SUPPORTED_PLATFORMS,
    getFormattedMetrics
} from '../helpers/storeHelpers';
import ApiRepository from '@/api-repository';
import router from '@/routes';
import { merge } from 'lodash';
import { setNestedValue } from '@/helpers/setNestedValue';

const apiRepository = new ApiRepository();

const {
    SET_PM_CURRENT_TAB,
    SET_PM_CAMPAIGNS,
    SET_PM_ADSETS,
    SET_PM_ADS,
    SET_PM_SELECTED_CAMPAIGNS,
    SET_PM_SELECTED_ADSETS,
    SET_PM_SELECTED_ADS,
    SET_PM_SELECTED_PLATFORMS,
    SET_PM_KEEP_ALIVE_KEY,
    SET_PM_DEPLOYMENTS,
    SET_PM_CAMPAIGN_MANAGING,
} = removeNamespace('playbookManagement/', mutationTypes);

export const TAB_DEPLOYED_ADS = OBJECT_TYPE_DEPLOYED_ADS;
export const TAB_CAMPAIGN = OBJECT_TYPE_CAMPAIGN;
export const TAB_ADSET = OBJECT_TYPE_ADSET;
export const TAB_AD = OBJECT_TYPE_AD;


export default {
    namespaced: true,
    state: {
        currentTab: null,
        campaigns: getInitialStructure(),
        selectedCampaigns: getInitialStructure(),
        adSets: getInitialStructure(),
        selectedAdSets: getInitialStructure(),
        ads: getInitialStructure(),
        selectedAds: getInitialStructure(),
        selectedPlatforms: [],
        keepAliveKey: Date.now(),
        managingCampaigns: false,
        deployments: [],
        hasBeenRedirected: false
    },
    getters: {
        /**
         * Create a unique hash based on all the current
         * selections which will be used as the key for each tab.
         * If the system is extended with filters in the
         * future this needs to be extended
         *
         * @todo Relying on this may be unsustainable but at the moment
         *  it allows for significant reduction in ancillary listeners, etc
         *
         * @param {Object} state
         * @param {Object} getters
         * @param {Object} rootState
         * @returns {Object}
         */
        tabKeys(state, getters, rootState) {

            const {
                startDate,
                endDate
            } = rootState.metrics.dateRange;


            const dateKey = `${getSimpleDate(startDate)}_${getSimpleDate(endDate)}`;

            const campaignCount = getters.objectTypeDetails[OBJECT_TYPE_CAMPAIGN].count;
            const campaignsHash = campaignCount ?
                hashCode(
                    JSON.stringify(state.selectedCampaigns)
                ) : 'na';

            const adSetCount = getters.objectTypeDetails[OBJECT_TYPE_ADSET].count;
            const adSetsHash = adSetCount ?
                hashCode(
                    JSON.stringify(state.selectedAdSets)
                ) : 'na';

            return {
                [TAB_DEPLOYED_ADS]: `${TAB_DEPLOYED_ADS}_${state.keepAliveKey}_${dateKey}`,
                [TAB_CAMPAIGN]: `${TAB_CAMPAIGN}_${state.keepAliveKey}_${dateKey}`,
                [TAB_ADSET]: `${TAB_ADSET}_${state.keepAliveKey}_${dateKey}_${campaignsHash}`,
                [TAB_AD]: `${TAB_AD}_${state.keepAliveKey}_${dateKey}_${campaignsHash}_${adSetsHash}`,
            };
        },
        objectTypeDetails(state) {
            const stateTypeMap = getStateTypeMap(state);

            // @todo this one might be overly abbreviated, maybe refactor me!?
            return Object.entries(stateTypeMap).reduce((details, [type, { selected }]) => {
                details[type] = {
                    count: Object.values(selected).reduce(sumArrayLengths, 0)
                };
                return details;
            }, {});
        },
        activePlatforms(state, getters, rootState, rootGetters) {
            return rootGetters.dealerPlatforms?.map(platform => platform.name)
                .filter((platform) => (
                    SUPPORTED_PLATFORMS.includes(platform))
                ) ?? [];
        },
        getNormalizedMetricTotals: (state, getters, rootState, rootGetters) => (platform) => {
            switch (platform) {
                case PLATFORM_FACEBOOK: {
                    const metrics = rootGetters['metrics/facebook/ads/metricsTotalsFormatted'];

                    if (!metrics) {
                        break;
                    }

                    return {
                        spend: metrics.spend.value,
                        impressions: metrics.impressions.value,
                        cpm: metrics.cpm.value,
                        clicks: metrics.clicks.value,
                        cost_per_click: metrics.cost_per_click.value,
                        leads: metrics.leads.value,
                        cost_per_lead: metrics.cost_per_lead.value,
                        sales: metrics.offline_purchase.value,
                        cost_per_sale: metrics.cost_per_offline_purchase.value,
                        frequency: metrics.frequency.value,
                        landing_page_view: metrics.landing_page_views.value,
                        cost_per_landing_page_view: metrics.cost_per_landing_page_view.value,
                        view_content: metrics.content_views.value,
                        cost_per_view_content: metrics.cost_per_content_view.value,
                    };
                }
                case PLATFORM_TIKTOK: {
                    const metrics = getFormattedMetrics(rootState.metrics.tiktok.ads.metricsTotals?.stats);

                    if (!metrics) {
                        break;
                    }

                    return {
                        spend: metrics.spend,
                        impressions: metrics.impressions,
                        cpm: metrics.cpm,
                        clicks: metrics.clicks,
                        cost_per_click: metrics.clicks_cost_per,
                        leads: metrics.online_consult,
                        cost_per_lead: metrics.online_consult_cost_per,
                        sales: metrics.offline_shopping_events,
                        cost_per_sale: metrics.cost_per_offline_shopping_event,
                        frequency: metrics.frequency,
                        landing_page_view: metrics.total_landing_page_view,
                        cost_per_landing_page_view: metrics.cost_per_landing_page_view,
                        view_content: metrics.page_content_view_events,
                        cost_per_view_content: metrics.cost_per_page_content_view_event,
                    };
                }
                case PLATFORM_SNAPCHAT: {
                    const metrics = getFormattedMetrics(rootState.metrics.snapchat.ads.metricsTotals?.stats);

                    if (!metrics) {
                        break;
                    }

                    return {
                        spend: metrics.spend,
                        impressions: metrics.impressions,
                        cpm: metrics.cpm,
                        clicks: metrics.swipes,
                        cost_per_click: metrics.swipes_cost_per,
                        leads: metrics.conversion_sign_ups,
                        cost_per_lead: metrics.conversion_sign_ups_cost_per,
                        sales: metrics.conversion_purchases,
                        cost_per_sale: metrics.cps,
                        frequency: metrics.frequency,
                        landing_page_view: null, // not available in SC
                        cost_per_landing_page_view: null, // not available in SC
                        view_content: metrics.conversion_view_content,
                        cost_per_view_content: metrics.conversion_view_content_cost_per,
                    };
                }
                case PLATFORM_PINTEREST: {
                    const metrics = getFormattedMetrics(rootState.metrics.pinterest.ads.metricsTotals?.stats);

                    if (!metrics) {
                        break;
                    }

                    const spend = metrics.spend;
                    const clicks = metrics.total_clickthrough;

                    return {
                        spend,
                        impressions: metrics.impressions,
                        cpm: metrics.cpm ? metrics.cpm : (spend / metrics.impressions) * 1000,
                        clicks,
                        cost_per_click: metrics.total_clickthrough_cost_per ? metrics.total_clickthrough_cost_per : spend / clicks,
                        leads: metrics.total_lead,
                        cost_per_lead: metrics.total_lead_cost_per,
                        sales: metrics.total_offline_checkout,
                        cost_per_sale: metrics.cps,
                        frequency: metrics.total_impression_frequency,
                        landing_page_view: null, // Destination Views is not available in the API
                        cost_per_landing_page_view: null, // Destination Views is not available in the API
                        view_content: metrics.total_add_to_cart,
                        cost_per_view_content: metrics.total_add_to_cart_cost_per,
                    };
                }
                default: {
                    return null;
                }
            }
        }
    },
    actions: {
        /**
         * Allows for viewing specific objects by type across
         * platforms -- primarily used by the deployment tab
         * when viewing completed deployments.
         *
         * @param {object} vuexArgs
         * @param {object} args
         * @param {object} args.data The primary data with structure:
         * { [platform]: [...objectIds] }
         * @param {object} args.type The type of object to target
         */
        viewObjects({ commit, dispatch, getters }, { data, type }) {

            dispatch('refreshKeepAlive');
            commit(SET_PM_CAMPAIGN_MANAGING, true);

            // Unselect across all types
            Object.values(mutationTypeMap).forEach(mutations => {
                commit(mutations.selected, getInitialStructure());
            });

            // Ensure all platforms are selected
            commit(SET_PM_SELECTED_PLATFORMS, getters.activePlatforms);

            if (type === OBJECT_TYPE_CAMPAIGN || type === OBJECT_TYPE_ADSET || type === OBJECT_TYPE_AD) {
                Object.entries(data.campaign_data).forEach(([platform, objects]) => {
                    dispatch('select', { type: OBJECT_TYPE_CAMPAIGN, platform, objects });
                });
            }

            if (type === OBJECT_TYPE_ADSET || type === OBJECT_TYPE_AD) {
                Object.entries(data.ad_set_data).forEach(([platform, objects]) => {
                    dispatch('select', { type: OBJECT_TYPE_ADSET, platform, objects });
                });
            }

            if (type === OBJECT_TYPE_AD) {
                Object.entries(data.ad_data).forEach(([platform, objects]) => {
                    dispatch('select', { type: OBJECT_TYPE_AD, platform, objects });
                });
            }

            // Open up the target type in a tab
            commit(SET_PM_CURRENT_TAB, type);
        },
        open({ commit, dispatch }, { platform, type, objects }) {
            const targetMap = {
                [OBJECT_TYPE_CAMPAIGN]: TAB_ADSET,
                [OBJECT_TYPE_ADSET]: TAB_AD
            };

            const targetTab = targetMap[type];

            dispatch('unselectAll', { type });
            dispatch('select', { type, platform, objects });

            commit(SET_PM_CURRENT_TAB, targetTab);
        },
        select({ state, commit }, { platform, type, objects }) {
            const stateTypeMap = getStateTypeMap(state);

            // Pull out the target state (e.g. `selectedCampaigns`)
            const selectedState = { ...stateTypeMap[type].selected };

            const newSelections = [
                ...selectedState[platform],
                ...objects
            ];

            // Remap unique selections into the state for the target platform
            selectedState[platform] = [...new Set(newSelections)];

            // Reapply the state
            commit(mutationTypeMap[type].selected, selectedState);
        },
        unselect({ state, commit }, { platform, type, objects }) {
            const stateTypeMap = getStateTypeMap(state);

            // Pull out the target state (e.g. `selectedCampaigns`)
            const selectedState = { ...stateTypeMap[type].selected };

            // Extract the current selections (e.g. `campaigns`)
            const newSelections = [...selectedState[platform]];

            // Find each object and remove it from the selections
            objects.forEach(object => {
                const index = newSelections.findIndex(selection => (
                    object === selection
                ));

                if (index > -1) {
                    newSelections.splice(index, 1);
                }
            });

            selectedState[platform] = newSelections;

            // Reapply the state
            commit(mutationTypeMap[type].selected, selectedState);
        },
        unselectAll({ state, commit }, { platform = null, type }) {
            const stateTypeMap = getStateTypeMap(state);

            // Pull out the target state (e.g. `selectedCampaigns`)
            const selectedState = { ...stateTypeMap[type].selected };

            // If a platform was defined just empty it
            if (platform) {
                selectedState[platform] = [];
            }
            // Otherwise empty the selections from all platforms
            else {
                Object.keys(selectedState).forEach(key => {
                    selectedState[key] = [];
                });
            }

            // Reapply the state
            commit(mutationTypeMap[type].selected, selectedState);
        },
        setPlatformObjects({ state, commit }, { platform, type, objects }) {
            const stateTypeMap = getStateTypeMap(state);

            // Pull out the target state (e.g. `campaigns`)
            const objectsState = { ...stateTypeMap[type].objects };

            objectsState[platform] = [...objects];

            // Reapply the state
            commit(mutationTypeMap[type].objects, objectsState);
        },
        /**
         * Allows consumers to reset all cached components
         * which will force all of them to be re-created
         * (effective reloading all data)
         *
         * @param {Object} params
         */
        refreshKeepAlive({ commit }) {
            commit(SET_PM_KEEP_ALIVE_KEY, Date.now());
        },
        reset({ dispatch, commit }) {
            commit(SET_PM_CAMPAIGN_MANAGING, false);

            Object.values(mutationTypeMap).forEach(mutations => {
                commit(mutations.selected, getInitialStructure());
                commit(mutations.objects, getInitialStructure());
            });

            commit(SET_PM_CURRENT_TAB, TAB_CAMPAIGN);

            dispatch('refreshKeepAlive');
        },
        /**
         * Removes all selections from child tab
         *
         * For example, if this is run on campaigns it
         * will drop everything under ad sets and ads
         *
         * @param {Object} params
         * @param {String} tab
         */
        removeChildren({ dispatch, getters }, tab) {
            const index = HIERARCHY.findIndex(item => item === tab);
            const children = HIERARCHY.slice(index + 1);

            children.forEach(child => {
                if (getters.objectTypeDetails[child].count) {
                    dispatch('unselectAll', {
                        type: child
                    });
                }
            });
        },
        async loadDeployments({ rootState, commit }) {
            const response = await apiRepository.getAdDeployments({
                dealerId: rootState.dealer.currentDealerId
            });

            commit(SET_PM_DEPLOYMENTS, response.data.data);
        },
        async getPreviewUrl({ rootState, dispatch }, data = null) {

            let previews;

            // If the data was provided directly use it
            // Otherwise grab it from the state
            if (data) {
                previews = await dispatch('getPreviewStructure', data);
            } else {
                previews = await dispatch('getPreviewStructureFromState');
            }

            let response = {};


            if (data?.dealer) {
                response = await apiRepository.createAdPreviewPublic({
                    dealerId: data.dealer.id,
                    agencyId: data.dealer.agency_id,
                    userId: null,
                    previews
                });
            } else {
                response = await apiRepository.createAdPreview({
                    dealerId: rootState.dealer.currentDealerId,
                    agencyId: rootState.agency.currentAgency.id,
                    userId: rootState.user.user.id,
                    previews
                });
            }

            // eslint-disable-next-line no-unused-vars
            const [_, uuid] = response.data.key.split(':');

            const route = router.resolve({
                name: 'ad-preview',
                query: {
                    id: uuid,
                }
            });

            // Default to the current origin
            let previewUrlOrigin = document.location.origin;

            // If we're in production and a brand it set
            // with a domain use it!
            if (
                process.env.VUE_APP_APP_ENV === 'production'
                && rootState.brand?.primary_domain
            ) {
                previewUrlOrigin = rootState.brand.primary_domain;
            }

            return previewUrlOrigin + route.href;
        },
        getPreviewStructure,
        getPreviewStructureFromState
    },
    mutations: {
        [SET_PM_CAMPAIGNS](state, value) {
            state.campaigns = value;
        },
        [SET_PM_ADSETS](state, value) {
            state.adSets = value;
        },
        [SET_PM_ADS](state, value) {
            state.ads = value;
        },
        [SET_PM_SELECTED_CAMPAIGNS](state, value) {
            state.selectedCampaigns = value;
        },
        [SET_PM_SELECTED_ADSETS](state, value) {
            state.selectedAdSets = value;
        },
        [SET_PM_SELECTED_ADS](state, value) {
            state.selectedAds = value;
        },
        [SET_PM_SELECTED_PLATFORMS](state, value) {
            state.selectedPlatforms = value;
        },
        [SET_PM_CURRENT_TAB](state, value) {
            state.currentTab = value;
        },
        [SET_PM_KEEP_ALIVE_KEY](state, value) {
            state.keepAliveKey = value;
        },
        [SET_PM_DEPLOYMENTS](state, value) {
            state.deployments = value;
        },
        [SET_PM_CAMPAIGN_MANAGING](state, value) {
            state.managingCampaigns = value;
        },
        setPlaybookManagementValue(state, { key, value }) {
            state = merge(state, setNestedValue(state, key, value));
        }
    }
};
