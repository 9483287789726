import ApiRepository from '@/api-repository';
import {
    SET_AGENCY,
    SET_AGENCY_LOADING,
    SET_AGENCIES,
    SET_AGENCIES_LOADING,
    SET_GROUPS,
    SET_SELECTED_GROUPS,
    SET_GROUPS_LOADING,
    SET_ORGANIZATION_CONFIGURATION,
    SET_AGENCY_PRIVACY_POLICY_IS_SIGNED,
    SET_AGENCY_TERMS_IS_SIGNED,
    SET_AGENCY_PRIVACY_POLICY_LOADED,
    SET_AGENCY_TERMS_LOADED
} from '../mutation-types';
import { AGENCY_STORAGE_KEY, SELECTED_GROUPS_STORAGE_KEY } from '@/helpers/storageKeys';
import orgConfiguration from '@/helpers/organizations/configuration';

const apiRepository = new ApiRepository();

const currentAgencyJson = localStorage.getItem(AGENCY_STORAGE_KEY);

export default {
    state: {
        currentAgency: currentAgencyJson ? JSON.parse(currentAgencyJson) : null,
        agencies: [],
        groups: [],
        selectedGroups: JSON.parse(localStorage.getItem(SELECTED_GROUPS_STORAGE_KEY)) || [],
        groupsLoading: false,
        agencyLoading: false,
        agenciesLoading: false,
        agencyPrivacyPolicyIsSigned: false,
        agencyTermsIsSigned: false,
        agencyPrivacyPolicyLoaded: false,
        agencyTermsLoaded: false,
    },
    getters: {
        agencyUsingGA(state) {
            return !!state?.currentAgency?.ga_account_id;
        },
        agencyHasDealers(state) {
            return Boolean(state.currentAgency?.dealers_count);
        },
        agencyHasWhiteLabelAccess(state) {
            return Boolean(state.currentAgency?.configuration?.[orgConfiguration.HAS_WHITE_LABEL_ACCESS]);
        },
        agencyHasDashboardWhiteLabelAccess(state) {
            return Boolean(state.currentAgency?.configuration?.[orgConfiguration.HAS_DASHBOARD_WHITE_LABEL_ACCESS]);
        },
        agencySupportButtonDisabled(state) {
            return Boolean(state.currentAgency?.configuration?.[orgConfiguration.SUPPORT_BUTTON_DISABLED]);
        },
        agencyIsLoaded(state) {
            return (
                !state.agencyLoading &&
                state.currentAgency !== null
            );
        },
    },
    actions: {
        async updateAgencies({ commit }) {
            try {
                commit(SET_AGENCIES_LOADING, true);

                const response = await apiRepository.getAgenciesNoRoot();
                const agencies = response.data.data;
                const frozenAgencies = agencies.map(Object.freeze);

                commit(SET_AGENCIES, frozenAgencies);

                return agencies;
            } catch (error) {
                console.error('Error retrieving agencies', error);
            } finally {
                commit(SET_AGENCIES_LOADING, false);
            }
        },
        waitForAgencyLoaded({ getters }) {
            return new Promise((resolve) => {
                (function checkAgencyLoaded() {
                    if (getters.agencyIsLoaded) {
                        return resolve();
                    }
                    setTimeout(checkAgencyLoaded, 30);
                })();
            });
        },
        async updateCurrentAgency({ state, commit, dispatch }) {
            try {
                const response = await apiRepository.getAgency(state.currentAgency.id);
                const agency = response.data.data;
                commit(SET_AGENCY, agency);
                //Dispatch an action that will update the brand for the dashboard when the agency changes
                dispatch('setBrandFromAgency', agency);
                return agency;
            } catch (error) {
                console.error('Error updating single agency', error);
            }
        },
        async updateAgencyById({ dispatch, commit, state }, agencyId) {
            try {
                let { agencies } = state;

                // If there's no agencies try to get them
                if (!agencies.length) {
                    agencies = await dispatch('updateAgencies');
                }

                const agency = agencies.find(agency => agency.id === agencyId);


                if (!agency) {
                    throw 'No matching agency found';
                }

                commit(SET_AGENCY, agency);

                //Dispatch an action that will update the brand for the dashboard when the agency changes
                dispatch('setBrandFromAgency', agency);

                return agency;

            } catch (error) {
                console.error('Error updating agency', error);
            }
        },
        async getGroups({ commit }, agencyId) {
            try {
                commit(SET_GROUPS_LOADING, true);
                const response = await apiRepository.getAccountGroups(agencyId);
                const groups = response.data.data;
                commit(SET_GROUPS, groups);
                if (groups.length === 0) { commit(SET_SELECTED_GROUPS, []) }
            } catch (error) {
                console.error('Error getting the groups', error);
            } finally {
                commit(SET_GROUPS_LOADING, false);
            }
        },
        setSelectedGroups({ commit }, groups) {
            localStorage.setItem(SELECTED_GROUPS_STORAGE_KEY, JSON.stringify(groups));
            commit(SET_SELECTED_GROUPS, groups);
        },
        async updateOrganizationConfiguration({ commit }, { organizationId, configuration }) {
            try {
                const updatedConfiguration = await apiRepository.updateOrganizationConfiguration(organizationId, configuration);

                commit(SET_ORGANIZATION_CONFIGURATION, updatedConfiguration);

                return updatedConfiguration;
            } catch (error) {
                console.error('Error updating agency', error);
            }
        },
        async getAgencyPrivacyPolicy({ commit }, agencyId) {
            try {
                const response = await apiRepository.getAgencyAgreement(agencyId, 'agency_legal_agreement_privacy_policy');

                if (!response.data || !response.data.document) {
                    commit(SET_AGENCY_PRIVACY_POLICY_IS_SIGNED, false);

                    return null;
                }

                commit(SET_AGENCY_PRIVACY_POLICY_IS_SIGNED, !!response.data.accepted);

                return response.data;
            } catch (error) {
                console.error('Error retrieving agency Privacy Policy:', error.response);

                throw error.response;
            } finally {
                commit(SET_AGENCY_PRIVACY_POLICY_LOADED, true);
            }
        },
        async getAgencyTerms({ commit }, agencyId) {
            try {
                const response = await apiRepository.getAgencyAgreement(agencyId, 'agency_legal_agreement_terms');

                commit(SET_AGENCY_TERMS_IS_SIGNED, !!response.data.accepted);

                return response.data;
            } catch (error) {
                console.error('Error retrieving agency Terms:', error.response);

                throw error.response;
            } finally {
                commit(SET_AGENCY_TERMS_LOADED, true);
            }
        }
    },
    mutations: {
        [SET_GROUPS](state, groups) {
            state.groups = groups;
        },
        [SET_SELECTED_GROUPS](state, groups) {
            state.selectedGroups = groups;
        },
        [SET_GROUPS_LOADING](state, isLoading) {
            state.groupsLoading = isLoading;
        },
        [SET_AGENCY](state, agency) {
            // Only commit when the agency is updated
            /* if (get(agency, 'id') === get(state.currentAgency, 'id')) {
                return;
            } */

            // Manipulate local storage for continuous use
            if (agency === null) {
                localStorage.removeItem(AGENCY_STORAGE_KEY);
            } else {
                localStorage.setItem(AGENCY_STORAGE_KEY, JSON.stringify(agency));
            }

            state.currentAgency = agency;

            // If there's no agencies set yet ensure the current agency is in the list
            // this is mostly a temporary precaution since agencies will always get populated
            // during init but the process is asynchronous so this ensures one is set
            // generally this ensures the agency picker has an option to show as the default
            if (agency && !state.agencies.length) {
                state.agencies = [agency];
            }
        },
        [SET_AGENCY_LOADING](state, isLoading) {
            state.agencyLoading = isLoading;
        },
        [SET_AGENCIES](state, agencies) {
            state.agencies = agencies;
        },
        [SET_AGENCIES_LOADING](state, isLoading) {
            state.agenciesLoading = isLoading;
        },
        [SET_ORGANIZATION_CONFIGURATION](state, configuration) {
            state.currentAgency = {
                ...state.currentAgency,
                configuration: { ...configuration },
            };
        },
        [SET_AGENCY_PRIVACY_POLICY_IS_SIGNED](state, isSigned) {
            state.agencyPrivacyPolicyIsSigned = isSigned;
        },
        [SET_AGENCY_TERMS_IS_SIGNED](state, isSigned) {
            state.agencyTermsIsSigned = isSigned;
        },
        [SET_AGENCY_PRIVACY_POLICY_LOADED](state, loaded) {
            state.agencyPrivacyPolicyLoaded = loaded;
        },
        [SET_AGENCY_TERMS_LOADED](state, loaded) {
            state.agencyTermsLoaded = loaded;
        }
    }
};
